import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const events = [
   {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-09-25T09:00:00'),
    end: new Date('2024-09-25T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter (Grades 1-4) Info Session & Tour',
    description: 'Lower School (Grades 1-4) Info Session & Tour at Lamplighter',
    start: new Date('2024-10-02T09:00:00'),
    end: new Date('2024-10-02T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-10-09T09:00:00'),
    end: new Date('2024-10-09T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-10-30T09:00:00'),
    end: new Date('2024-10-30T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Open House',
    description: 'Family Friendly Open House at Lamplighter',
    start: new Date('2024-11-03T13:00:00'),
    end: new Date('2024-11-03T15:00:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-11-06T09:00:00'),
    end: new Date('2024-11-06T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-11-13T09:00:00'),
    end: new Date('2024-11-13T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-11-20T09:00:00'),
    end: new Date('2024-11-20T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2024-12-04T09:00:00'),
    end: new Date('2024-12-04T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Application Deadline',
    description: 'Application Deadline for Lamplighter',
    start: new Date('2024-12-13T00:00:00'),
    end: new Date('2024-12-13T23:59:59'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2025-01-15T09:00:00'),
    end: new Date('2025-01-15T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Supplemental Documents Due',
    description: 'Supplemental Documents Due for Lamplighter',
    start: new Date('2025-02-05T17:00:00'),
    end: new Date('2025-02-05T17:00:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tuition Assistance Applications Due',
    description: 'Tuition Assistance applications due for Lamplighter',
    start: new Date('2025-02-13T00:00:00'),
    end: new Date('2025-02-13T23:59:59'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Admission Decisions',
    description: 'Admission Decisions released for Lamplighter',
    start: new Date('2025-03-14T16:00:00'),
    end: new Date('2025-03-14T16:00:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Tour',
    description: 'Campus Tour at Lamplighter',
    start: new Date('2025-04-16T09:00:00'),
    end: new Date('2025-04-16T10:30:00'),
    location: 'Lamplighter School',
  },
  {
    title: 'Lamplighter Admission Decisions - Parent Response',
    description: 'Admission Decisions - Parent Response due for Lamplighter',
    start: new Date('2025-03-28T00:00:00'),
    end: new Date('2025-03-28T23:59:59'),
    location: 'Lamplighter School',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD',
    start: new Date('2024-09-24T09:15:00'),
    end: new Date('2024-09-24T10:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Middle/Upper School Tour',
    description: 'Middle/Upper School Tour at ESD',
    start: new Date('2024-10-09T10:15:00'),
    end: new Date('2024-10-09T11:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD',
    start: new Date('2024-10-01T09:15:00'),
    end: new Date('2024-10-01T10:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD (check website for start time - registration required)',
    start: new Date('2024-10-01T17:30:00'),
    end: new Date('2024-10-01T19:00:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Middle/Upper School Tour',
    description: 'Middle/Upper School Tour at ESD (check website for start time)',
    start: new Date('2024-10-16T17:30:00'),
    end: new Date('2024-10-16T19:00:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Upper School Tour',
    description: 'Upper School Tour Plus at ESD',
    start: new Date('2024-10-23T13:00:00'),
    end: new Date('2024-10-23T14:30:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD  (registration required)',
    start: new Date('2024-11-05T09:15:00'),
    end: new Date('2024-11-05T10:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Middle/Upper School Tour',
    description: 'Middle/Upper School Tour at ESD',
    start: new Date('2024-11-20T10:15:00'),
    end: new Date('2024-11-20T11:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD All School Open House',
    description: 'All School Open House (Beginners - Grade 11) at ESD. See website for times and programming',
    start: new Date('2024-12-08T12:15:00'),
    end: new Date('2024-12-08T14:15:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Early Childhood Application Deadline',
    description: 'Early Childhood (Beg - Kinder) application deadline for ESD',
    start: new Date('2024-12-13T00:00:00'),
    end: new Date('2024-12-13T23:59:59'),
    location: 'ESD',
  },
  {
    title: 'ESD Grades 1-11 Application Deadline',
    description: 'Grades 1-11 application deadline for ESD',
    start: new Date('2025-01-13T00:00:00'),
    end: new Date('2025-01-13T23:59:59'),
    location: 'ESD',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD (registration required)',
    start: new Date('2025-01-14T09:15:00'),
    end: new Date('2025-01-14T10:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Upper School Tour Plus',
    description: 'Upper School Tour Plus at ESD',
    start: new Date('2025-01-15T13:00:00'),
    end: new Date('2025-01-15T14:30:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Middle/Upper School Tour',
    description: 'Middle/Upper School Tour at ESD',
    start: new Date('2025-01-22T10:15:00'),
    end: new Date('2025-01-22T11:45:00'),
    location: 'ESD',
  },
  {
    title: 'ESD Tuition Assistance Applications Due',
    description: 'Tuition Assistance applications due for ESD',
    start: new Date('2025-02-15T00:00:00'),
    end: new Date('2025-02-15T23:59:59'),
    location: 'ESD',
  },
  {
    title: 'ESD Admission Decisions Released',
    description: 'Admission Decisions released for ESD',
    start: new Date('2025-03-14T00:00:00'),
    end: new Date('2025-03-14T23:59:59'),
    location: 'ESD',
  },
  {
    title: 'ESD Lower School Tour',
    description: 'Lower School Tour at ESD (registration required)',
    start: new Date('2025-04-23T09:15:00'),
    end: new Date('2025-04-23T10:45:00'),
    location: 'ESD',
  },
  {
    title: 'St. John\'s Primary & Lower School Admission Coffee & Tour',
    description: 'Primary & Lower School Admission Coffee & Tour at St. John\'s Episcopal School (registration required)',
    start: new Date('2024-09-26T09:30:00'),
    end: new Date('2024-09-26T11:00:00'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Primary & Lower School Admission Coffee & Tour',
    description: 'Primary & Lower School Admission Coffee & Tour at St. John\'s Episcopal School  (registration required)',
    start: new Date('2024-10-17T09:30:00'),
    end: new Date('2024-10-17T11:00:00'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Middle School Admission Coffee & Tour',
    description: 'Middle School Admission Coffee & Tour at St. John\'s Episcopal School  (registration required)',
    start: new Date('2024-10-24T09:30:00'),
    end: new Date('2024-10-24T11:00:00'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Admission Open House',
    description: 'Admission Open House for Preschool - 8th grade at St. John\'s Episcopal School  (registration required)',
    start: new Date('2024-11-17T14:00:00'),
    end: new Date('2024-11-17T16:00:00'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Admission Application Deadline',
    description: 'Admission Application Deadline for St. John\'s Episcopal School',
    start: new Date('2025-01-10T00:00:00'),
    end: new Date('2025-01-10T23:59:59'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Tuition Assistance Applications Due',
    description: 'Tuition Assistance applications due for St. John\'s Episcopal School',
    start: new Date('2025-01-27T00:00:00'),
    end: new Date('2025-01-27T23:59:59'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'St. John\'s Admission Decisions Released',
    description: 'Admission Decisions Released for St. John\'s Episcopal School',
    start: new Date('2025-03-14T16:00:00'),
    end: new Date('2025-03-14T16:00:00'),
    location: 'St. John\'s Episcopal School',
  },
  {
    title: 'Shelton Early Childhood Fall Event',
    description: 'Early Childhood Fall Event at Shelton School (registration encouraged)',
    start: new Date('2024-10-19T15:00:00'),
    end: new Date('2024-10-19T16:00:00'),
    location: 'Shelton School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2024-10-17T09:00:00'),
    end: new Date('2024-10-17T10:30:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2024-11-21T09:00:00'),
    end: new Date('2024-11-21T10:30:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2024-12-12T09:00:00'),
    end: new Date('2024-12-12T10:30:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2025-01-16T09:00:00'),
    end: new Date('2025-01-16T10:30:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2025-02-02T14:00:00'),
    end: new Date('2025-02-02T17:00:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2025-03-27T09:00:00'),
    end: new Date('2025-03-27T10:30:00'),
    location: 'The Winston School',
  },
  {
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2025-04-24T09:00:00'),
    end: new Date('2025-04-24T10:30:00'),
    location: 'The Winston School',
  },
{
    title: 'Winston Admission Coffee & Tour',
    description: 'Admission Coffee & Tour at The Winston School (registration required)',
    start: new Date('2025-05-08T09:00:00'),
    end: new Date('2025-05-08T10:30:00'),
    location: 'The Winston School',
  },
{
    title: 'Hockaday Lower School Parent Visit',
    description: 'Lower School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-09-19T08:30:00'),
    end: new Date('2024-09-19T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Upper School Parent Visit',
    description: 'Upper School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-09-26T09:30:00'),
    end: new Date('2024-09-26T11:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Middle School Parent Visit',
    description: 'Middle School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-10-04T08:30:00'),
    end: new Date('2024-10-04T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Lower School Admission Preview',
    description: 'Lower School Admission Preview at The Hockaday School (registration encouraged)',
    start: new Date('2024-10-20T14:00:00'),
    end: new Date('2024-10-20T15:30:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Upper School Parent Visit',
    description:  'Upper School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-10-24T09:30:00'),
    end: new Date('2024-10-24T11:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Middle & Upper School Admission Preview',
    description:  'Middle & Upper School Admission Preview at The Hockaday School (registration encouraged)',
    start: new Date('2024-11-03T14:00:00'),
    end: new Date('2024-11-03T16:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Middle School Parent Visit',
    description:  'Middle School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-11-13T08:30:00'),
    end: new Date('2024-11-13T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Lower School Parent Visit',
    description:  'Lower School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-11-14T08:30:00'),
    end: new Date('2024-11-14T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Upper School Parent Visit',
    description:  'Upper School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-12-10T09:30:00'),
    end: new Date('2024-12-10T11:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Middle School Parent Visit',
    description:  'Middle School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-12-11T08:30:00'),
    end: new Date('2024-12-11T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Lower School Parent Visit',
    description:  'Lower School Parent Visit at The Hockaday School (registration encouraged)',
    start: new Date('2024-12-12T08:30:00'),
    end: new Date('2024-12-12T10:00:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Hockaday Inclusion and Belonging Panel & Reception',
    description:  'Inclusion and Belonging Panel & Reception at The Hockaday School (registration encouraged)',
    start: new Date('2025-01-26T14:00:00'),
    end: new Date('2025-01-26T15:30:00'),
    location: 'The Hockaday School',
  },
{
    title: 'Cistercian Admission Coffee & Campus Tour',
    description:  'Admission Coffee & Campus Tour at Cistercian Preparatory School (registration encouraged)',
    start: new Date('2024-09-24T08:30:00'),
    end: new Date('2024-09-24T10:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admissions Open House',
    description:  'Admissions Open House at Cistercian Preparatory School',
    start: new Date('2024-10-03T08:00:00'),
    end: new Date('2024-10-03T14:45:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian - Saturday at Cistercian',
    description:  'Saturday at Cistercian Preparatory School (registration required)',
    start: new Date('2024-10-19T10:00:00'),
    end: new Date('2024-10-19T12:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admission Coffee & Campus Tour',
    description: 'Admission Coffee & Campus Tour at Cistercian Preparatory School (registration encouraged)',
    start: new Date('2024-10-29T08:30:00'),
    end: new Date('2024-10-29T10:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admission Coffee & Campus Tour',
    description:  'Admission Coffee & Campus Tour at Cistercian Preparatory School (registration encouraged)',
    start: new Date('2024-11-19T08:30:00'),
    end: new Date('2024-11-19T10:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian - Saturday at Cistercian',
    description:  'Saturday at Cistercian Preparatory School (registration required)',
    start: new Date('2024-12-12T10:00:00'),
    end: new Date('2024-12-12T12:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admissions Testing',
    description:  'Admissions Testing at Cistercian Preparatory School (completed application required to sit for test)',
    start: new Date('2025-01-25T09:00:00'),
    end: new Date('2025-01-25T12:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admissions Testing',
    description:  'Admissions Testing at Cistercian Preparatory School (completed application required to sit for test)',
    start: new Date('2025-02-01T09:00:00'),
    end: new Date('2025-02-01T12:00:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Cistercian Admissions Open House',
    description: 'Admissions Open House at Cistercian Preparatory School',
    start: new Date('2025-03-25T08:00:00'),
    end: new Date('2025-03-25T11:45:00'),
    location: 'Cistercian Preparatory School',
  },
{
    title: 'Alcuin Prospective Parent Meeting for Early Childhood',
    description: 'Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2024-09-17T09:00:00'),
    end: new Date('2024-09-17T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alcuin Virtual Prospective Parent Meeting for Lower School',
    description:  'Virtual Prospective Parent Meeting for Lower School (1st - 6th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-09-24T09:00:00'),
    end: new Date('2024-19-24T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Virtual Prospective Parent Meeting for Early Childhood',
    description:  'Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2024-10-08T09:00:00'),
    end: new Date('2024-10-08T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for ISAS Families',
    description:  'Prospective Parent Meeting for ISAS Families (5th - 12th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-10-15T09:00:00'),
    end: new Date('2024-10-15T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Early Childhood',
    description:  'Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2024-10-22T09:00:00'),
    end: new Date('2024-10-22T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Virtual Prospective Parent Meeting for Middle & Upper School',
    description:  'Virtual Prospective Parent Meeting for Middle & Upper School (7th - 12th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-10-29T09:00:00'),
    end: new Date('2024-10-29T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Virtual Prospective Parent Meeting for Early Childhood',
    description:  'Virtual Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2024-11-05T09:00:00'),
    end: new Date('2024-11-05T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Lower School',
    description:  'Prospective Parent Meeting for Lower School (1st - 6th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-11-12T09:00:00'),
    end: new Date('2024-11-12T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Open House for All Levels',
    description:  'Open House for All Levels at Alcuin School (registration required)',
    start: new Date('2024-11-17T12:00:00'),
    end: new Date('2024-11-17T14:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Middle & Upper School',
    description:  'Prospective Parent Meeting for Middle & Upper School (7th - 12th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-12-03T09:00:00'),
    end: new Date('2024-12-03T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Lower School',
    description:  'Prospective Parent Meeting for Lower School (1st - 6th grades) at Alcuin School (registration encouraged)',
    start: new Date('2024-12-10T09:00:00'),
    end: new Date('2024-12-10T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Early Childhood',
    description:  'Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2024-12-17T09:00:00'),
    end: new Date('2024-12-17T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Virtual Prospective Parent Meeting for Lower School',
    description:  'Virtual Prospective Parent Meeting for Lower School (1st - 6th grades) at Alcuin School (registration encouraged)',
    start: new Date('2025-01-14T09:00:00'),
    end: new Date('2025-01-14T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Prospective Parent Meeting for Early Childhood',
    description:  'Prospective Parent Meeting for Early Childhood (18mo - 6 yrs) at Alcuin School (registration encouraged)',
    start: new Date('2025-01-21T09:00:00'),
    end: new Date('2025-01-21T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Alucin Virtual Prospective Parent Meeting for Middle & Upper School',
    description:  'Virtual Prospective Parent Meeting for Middle & Upper School (7th - 12th grades) at Alcuin School (registration encouraged)',
    start: new Date('2025-01-28T09:00:00'),
    end: new Date('2025-01-28T10:30:00'),
    location: 'Alcuin School',
  },
{
    title: 'Greenhill Preschool/Lower School Admission Preview',
    description:  'Preschool/Lower School Admission Preview at Greenhill School (registration encouraged)',
    start: new Date('2024-10-17T18:00:00'),
    end: new Date('2024-10-17T20:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Middle & Upper School Admission Preview',
    description:  'Middle & Upper School Admission Preview at Greenhill School (registration encouraged)',
    start: new Date('2024-11-17T14:00:00'),
    end: new Date('2024-11-17T16:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Preschool/Lower School Parent Visit',
    description:  'Preschool/Lower School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-09-24T09:30:00'),
    end: new Date('2024-19-24T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Upper School Parent Visit',
    description:  'Upper School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-10-29T09:30:00'),
    end: new Date('2024-10-29T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Preschool/Lower School Parent Visit',
    description:  'Preschool/Lower School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-11-07T09:30:00'),
    end: new Date('2024-11-07T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Middle School Parent Visit',
    description:  'Middle School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-12-05T09:30:00'),
    end: new Date('2024-12-05T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Preschool/Lower School Parent Visit',
    description:  'Preschool/Lower School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-12-10T09:30:00'),
    end: new Date('2024-12-10T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Upper School Parent Visit',
    description:  'Upper School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2024-12-16T09:30:00'),
    end: new Date('2024-12-16T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Preschool/Lower School Parent Visit',
    description:  'Preschool/Lower School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2025-01-08T09:30:00'),
    end: new Date('2025-01-08T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Middle School Parent Visit',
    description:  'Middle School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2025-01-13T09:30:00'),
    end: new Date('2025-01-13T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Upper School Parent Visit',
    description:  'Upper School Parent Visit at Greenhill School (registration encouraged)',
    start: new Date('2025-01-14T09:30:00'),
    end: new Date('2025-01-14T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Spring Preview',
    description:  'Spring Preview (registration encouraged)',
    start: new Date('2025-04-29T09:30:00'),
    end: new Date('2025-04-29T11:00:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill Preschool/Lower School Application Deadline',
    description:  'Preschool/Lower School Application Deadline at Greenhill School',
    start: new Date('2024-12-13T23:59:00'),
    end: new Date('2024-12-13T23:59:00'),
    location: 'Greenhill School',
  },
{
    title: 'Greenhill All Grades Completed Application Deadline',
    description:  'All Grades Completed Application Deadline at Greenhill School',
    start: new Date('2025-01-27T23:59:00'),
    end: new Date('2025-01-27T23:59:00'),
    location: 'Greenhill School',
  },
{
    title: 'St. Philip\'s - Life After St. Philip\'s High School Fair',
    description:  'Life After St. Philip\'s High School Fair (open to all graduating 8th graders) at St. Philip\'s School',
    start: new Date('2024-09-24T16:30:00'),
    end: new Date('2024-09-24T18:30:00'),
    location: 'St. Philip\'s School & Community Center',
  },
{
    title: 'St. Philip\'s - Virtual Open House',
    description: 'Virtual Open House at St. Philip\'s School (https://www.stphilips1600.org/admissions/preview.cfm)',
    start: new Date('2024-10-09T17:30:00'),
    end: new Date('2024-10-09T18:30:00'),
    location: 'St. Philip\'s School & Community Center',
  },
{
    title: 'St. Philip\'s - In-Person Admissions Preview',
    description: 'In-Person Admissions Preview at St. Philip\'s School (https://www.stphilips1600.org/admissions/preview.cfm)',
    start: new Date('2024-11-11T08:00:00'),
    end: new Date('2024-11-11T10:00:00'),
    location: 'St. Philip\'s School & Community Center',
  },
{
    title: 'St. Philip\'s - In-Person Admissions Preview',
    description: 'In-Person Admissions Preview at St. Philip\'s School (https://www.stphilips1600.org/admissions/preview.cfm)',
    start: new Date('2024-12-13T08:00:00'),
    end: new Date('2024-12-13T10:00:00'),
    location: 'St. Philip\'s School & Community Center',
  },
{
    title: 'St. Philip\'s - Virtual Open House',
    description: 'Virtual Open House at St. Philip\'s School (https://www.stphilips1600.org/admissions/preview.cfm)',
    start: new Date('2025-01-15T17:30:00'),
    end: new Date('2025-01-15T18:30:00'),
    location: 'St. Philip\'s School & Community Center',
  },
{
    title: 'Ursuline Campus Tour',
    description: 'Campus Tour at Ursuline Academy (registration required)',
    start: new Date('2024-09-27T09:30:00'),
    end: new Date('2024-09-27T10:30:00'),
    location: 'Ursuline Academy',
  },
{
    title: 'Ursuline 8th Grade Open Visit',
    description: '8th Grade Open Visit at Ursuline Academy (registration required)',
    start: new Date('2024-10-15T09:30:00'),
    end: new Date('2024-10-15T13:30:00'),
    location: 'Ursuline Academy',
  },
{
    title: 'Ursuline Campus Tour',
    description: 'Campus Tour at Ursuline Academy (registration required)',
    start: new Date('2024-10-18T09:30:00'),
    end: new Date('2024-10-18T10:30:00'),
    location: 'Ursuline Academy',
  },
{
    title: 'Ursuline Meet the Administrators Coffee',
    description: 'Meet the Administrators Coffee at Ursuline Academy (registration required)',
    start: new Date('2024-10-21T08:00:00'),
    end: new Date('2024-10-21T09:00:00'),
    location: 'Ursuline Academy',
  },
{
    title: 'Ursuline Tarde de Informacion con Ursuline',
    description: 'Tarde de Informacion con Ursuline (registration required)',
    start: new Date('2024-10-24T18:30:00'),
    end: new Date('2024-10-24T19:30:00'),
    location: 'Ursuline Academy',
  },
{
    title: 'Ursuline Admission Showcase',
    description: 'Admission Showcase at Ursuline Academy (registration recommended)',
    start: new Date('2024-11-16T13:00:00'),
    end: new Date('2024-11-16T15:00:00'),
    location: 'Ursuline Academy',
  },





  //more to do
];


const getUpcomingEvents = (events, count) => {
  const now = new Date();
  return events
    .filter(event => event.start >= now)
    .sort((a, b) => a.start - b.start)
    .slice(0, count);
};

export default function Events() {
  const [visibleCount, setVisibleCount] = useState(3);

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 3);
  };

  const upcomingEvents = getUpcomingEvents(events, visibleCount);

  return (
    <div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        className="bg-white p-4 border border-gray-200 rounded-lg shadow-md"
      />
      {upcomingEvents.map((event, index) => (
        <div key={index} className="bg-white shadow-md rounded-md p-4 mb-4">
          <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
          <p className="text-gray-600 mb-2">{event.description}</p>
          <p className="text-gray-500">Date: {event.start.toLocaleString()}</p>
          <p className="text-gray-500">Location: {event.location}</p>
        </div>
      ))}
      <div className="flex justify-center pb-4">
        {visibleCount < events.filter(event => event.start >= new Date()).length && (
          <button
            onClick={handleShowMore}
            className="bg-isaad-green centering flex items-center item-center align-center text-white px-4 py-2 rounded-md"
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
}


